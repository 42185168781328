import { $html } from './utils/environment';

// namings
const NAME = 'App';

class App {
    /**
     * Init the app
     *
     * @param firstBlood: is needed for pages with site transition
     *                    so we do not load globals multiple times
     */
    init (firstBlood) {
        if (firstBlood)
        {
            this.globals();
        }

        // init controllers
        this.import(firstBlood);
    }

    globals () {
        // set fullScreen
        const fullscreen = import(/* webpackChunkName: "utils/fullScreen" */ './utils/fullScreen');
        fullscreen.then(e => e.default()).catch(error => console.error(error));

        // set browser
        const browsers = import(/* webpackChunkName: "utils/browsers" */ './utils/browsers');
        browsers.then((e) => {
            if (e.default.isIE())
            {
                $html.addClass('is-ie');
            }

            if (e.default.isFirefox())
            {
                $html.addClass('is-firefox');
            }

            if (e.default.isIOS())
            {
                $html.addClass('is-ios');
            }
        }).catch(error => console.error(error));
    }

    import () {
        //const progressive = import(/* webpackChunkName: "controllers/Progressive" */ './controllers/ProgressiveController');
        const modules = import(/* webpackChunkName: "controllers/Modules" */ './controllers/ModuleController');

        this.loadedControllers = [];

        // always load these controllers
        const controllers = [modules];

        if ($html.hasClass('p-logged-in'))
        {
            // only show image dimensions when logged in CMS
            const images = import(/* webpackChunkName: "controllers/Images" */ './controllers/ImageController');
            controllers.push(images);

            // remove parallax classes on images
            $.each($('.o-background--parallax'), (i, e) => $(e).removeClass('o-background--parallax'));
        }
        else
        {
            // only enable parallax functions when not logged in CMS
            const scroll = import(/* webpackChunkName: "controllers/Scroll" */ './controllers/ScrollController');
            controllers.push(scroll);
        }

        // load all controllers
        Promise.all(controllers)
            .then((data) => {
                console.log(`%c${NAME} is done loading`, 'color: yellow;');

                $.each(data, (i, e) => {
                    const controller = new e.default();
                    controller.init();

                    this.loadedControllers.push(controller);
                });

                // flag window as loaded
                $html.addClass('is-loaded');
            })
            .catch(error => console.error(error));
    }

    /**
     * Reload the app
     *
     * Can be used after an ajax call to reload the DOM
     */
    reinit () {
        console.log(`%c${NAME} has been reloaded`, 'color: yellow;');

        // reinit progressive controller
        this.loadedControllers[0].destroy().init();

        // reinit module controller
        this.loadedControllers[1].destroy().init();

        // reinit scroll controller
        if (this.loadedControllers[2])
        {
            this.loadedControllers[2].destroy().rebuild();
        }
    }
}

// init app
window[NAME] = new App();
window[NAME].init(true);

const ANIMATION_DELAY = 600; // delay for when animation is done
const $document = $(document);
const $window = $(window);
const $html = $(document.documentElement);
const $body = $(document.body);

export {
    $document,
    $window,
    $html,
    $body,
    ANIMATION_DELAY,
};
